<template>
  <div class="min-h-full" v-if="$store.state.authIsReady">
    <div class="bg-slate-800 pb-32" >
      <Disclosure as="nav" class="bg-slate-800" v-slot="{ open }">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="border-b border-slate-700">
            <div class="flex h-16 items-center justify-between px-4 sm:px-0">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  
                </div>
                <div class="hidden md:block">
                  <div class=" flex items-baseline space-x-4 text-white text-medium select-none text-xs">
                      Lauron Sp. z o.o.
                    <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-slate-900 text-white' : 'text-slate-300 hover:bg-slate-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a> -->
                  </div>
                </div>
              </div>
              <div class="hidden md:block">
                <div class="ml-4 flex items-center md:ml-6">

                  <!-- Profile dropdown -->
                  <Menu as="div" class="relative ml-3">
                    <div>
                      <MenuButton class="flex max-w-xs items-center rounded-full bg-slate-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-slate-800">
                        <span class="sr-only">Open user menu</span>
                        <img class="h-8 w-8 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${$store.state.userData.name} ${$store.state.userData.surname}`" alt="" />
                      </MenuButton>
                    </div>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <MenuItem >
                          <div @click.prevent="logout" class="block px-4 py-2 text-sm text-slate-700 cursor-pointer hover:bg-slate-100">Wyloguj się</div>
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>
              <div class="-mr-2 flex md:hidden">
                <!-- Mobile menu button -->
                <DisclosureButton class="inline-flex items-center justify-center rounded-md bg-slate-800 p-2 text-slate-400 hover:bg-slate-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-slate-800">
                  <span class="sr-only">Open main menu</span>
                  <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                  <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                </DisclosureButton>
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel class="border-b border-slate-700 md:hidden">
          <div class="space-y-1 px-2 py-3 sm:px-3">
            
          </div>
          <div class="border-t border-slate-700 pt-4 pb-3">
            <div class="flex items-center px-5">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${$store.state.userData.name} ${$store.state.userData.surname}`" alt="" />
              </div>
              <div class="ml-3">
                <div class="text-base font-medium leading-none text-white">{{ $store.state.userData.name}}</div>
                <div class="text-sm font-medium leading-none text-slate-400">{{ $store.state.userData.surname }}</div>
              </div>
            </div>
            <div class="mt-3 space-y-1 px-2">
              <DisclosureButton @click.prevent="logout"  class="block rounded-md px-3 py-2 text-base font-medium text-slate-400 hover:bg-slate-700 hover:text-white">Wyloguj się</DisclosureButton>
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>
      <header class="py-10">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold tracking-tight text-white select-none">Wybierz moduł</h1>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">

        <!-- <div class="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <div class="h-96 rounded-lg border-4 border-dashed border-slate-200" />
        </div> -->

        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2" v-if="!hubLoading">
            <div v-for="mdl in modules" :key="mdl" class="select-none  relative flex items-center space-x-3 rounded-lg border border-slate-300 bg-white hover:bg-slate-100 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-slate-500 focus-within:ring-offset-2 hover:shadow-lg hover:border-slate-300 transition-all duration-300">
                <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" :src="mdl.introduction.img" alt="" />
                </div>
                <div class="min-w-0 flex-1 ">
                    <router-link :to="mdl.url" class="focus:outline-none">
                        <span class="absolute inset-0" aria-hidden="true" />
                        <p class="text-sm font-medium text-slate-900">{{ mdl.introduction.header }}</p>
                        <p class="truncate text-sm text-slate-500">{{ mdl.introduction.subheader }}</p>
                    </router-link>
                </div>
            </div>
        </div>
        <Loader v-if="hubLoading"></Loader>
        <div v-if="!hubLoading" class="text-slate-500 text-xs mt-14 select-none flex items-center justify-center">
          <p class="text-center text-xs text-gray-400">&copy; 2022 Powered by <a href="https://complaia.systems/" class="transition ease-in-out duration-300 text-transparent font-medium bg-clip-text bg-gradient-to-r from-purple-500 to-blue-500 opacity-90 hover:opacity-100">Complaia Systems</a></p>
        </div>
      </div>
    </main>
    
  </div>
</template>

<script>
    import { auth,db } from "@/firebase/gfbconf";
    import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
    import { MenuIcon as Bars3Icon, XIcon as XMarkIcon } from '@heroicons/vue/solid'
    import Loader from '@/components/LoadingSpinnerHub.vue';
    //import axios from 'axios';
    //import {db,storage} from "@/firebase/gfbconf.js"
    export default {
        name: "Hub",
        data()
        {
            return {
                modules: [],
                hubLoading: false
            }
        },
        watch:{
            '$route'()
            {
                this.$store.commit("resetGlobalLoader");
            }
        },
        components: {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Bars3Icon, XMarkIcon,Loader},
        created()
        {
            // Auth guard
            const loginSubscription = auth.onAuthStateChanged(async (user) => {
                if(user)
                {
                    
                    this.$store.commit("setUser", user);
                    
                    // Get user data by id from db
                    let userCred = await db.collection("WorkersData").doc(this.$store.state.user.uid).get();
                    if(userCred.exists)
                    {
                        this.$store.commit('setUserCredentials', userCred.data());
                        this.$store.commit("setAuthIsReady", true);
                        await this.getModules();
                    }
                    else
                    {
                        this.$router.push("/");
                    }
                    
                }
                else
                {
                    this.$router.push("/");
                }

            })



        },
        methods:
        {
            // Get menu options
            async getModules()
            {
              this.hubLoading = true;
              const hubList = await db.collection("HubList").get();
              if(hubList.empty)
              {
                  this.modules = [];
              }
              else
              {
                  for(let i=0; i<hubList.docs.length; i++)
                  {
                    let moduleData = hubList.docs[i].data();
                    if(this.$store.state.userData.permissions[moduleData.permission] === true || (moduleData.permission === "users" && this.$store.state.userData.permissions.admin === true))
                    {
                      this.modules.push(hubList.docs[i].data());
                    }
                    this.modules.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
                }
                this.modules.push({
                  introduction: {
                    header: "Niezafakturowane transakcje",
                    img: "https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2Fpexels-jakub-zerdzicki-16600135.jpg?alt=media&token=ac32391c-a144-4ec5-a184-20ab64a98371",
                    permission: "user",
                    url: "/dashboard/uninvoicedTransactions"
                  },
                  permission: "user",
                  url: "/dashboard/uninvoicedTransactions"
                },
                {
                  introduction: {
                    header: "Nota odsetkowa",
                    img: "https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2FinterestNote.jpg?alt=media&token=a960cd13-df79-4838-97c0-f49b62cd3e04",
                    permission: "user",
                    url: "/dashboard/interestNotes"
                  },
                  permission: "user",
                  url: "/dashboard/interestNotes"
                }
                )  
              }
              setTimeout(()=>
              {
                this.hubLoading = false;
              },500)
            },
            async logout() {
                try {
                    await this.$store.dispatch("logout");
                    this.$router.push("/");
                } catch (err) {
                    console.log(err.message);
                }
            }
        },
        computed:
        {
        }
    }
</script>
<style lang="scss" scoped>

</style>